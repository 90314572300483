import { graphql } from "gatsby";
import BlogPost from "../components/sanity/blog-post";
import React from "react";
import GraphQLErrorList from "../components/sanity/graphql-error-list";
import Layout from "../components/layout";
import Container from "../components/sanity/container";
import SEO from "../components/sanity/seo";
import { toPlainText } from "../lib/helpers";

export const query = graphql`
  query NewsEventsTemplate($id: String!) {
    post: sanityNewspage(id: { eq: $id }) {
      id
      publishedAt
      categories {
        _id
        title
      }
      mainImage {
        ...SanityImage
        alt
      }
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      authors {
        _key
        author {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
        }
      }
    }
  }
`;

const NewsEventsTemplate = (props) => {
    const { data, errors } = props;
    const post = data && data.post;
    return (
        <Layout>
            {errors && <SEO title="GraphQL Error" />}
            {post && (
                <SEO
                    title={post.title || "Untitled"}
                    description={toPlainText(post._rawExcerpt)}
                    image={post.mainImage}
                />
            )}

            {errors && (
                <Container>
                    <GraphQLErrorList errors={errors} />
                </Container>
            )}

            {post && <BlogPost {...post} />}
        </Layout>
    );
};

export default NewsEventsTemplate;
