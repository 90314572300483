import * as styles from "./blog-post.module.css";
import { differenceInDays, formatDistance, format } from "date-fns";
import AuthorList from "./author-list";
import Container from "./container";
import PortableText from "./portableText";
import React from "react";
import { buildImageObj } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";
import BannerImage from "../../components/master/BannerImage";
import PageTitle from "../../components/master/PageTittle";

function BlogPost(props) {
  const { _rawBody, authors, categories, title, mainImage, publishedAt } =
    props;
  return (
    <article className={styles.root}>
      <BannerImage
        imagePath={imageUrlFor(buildImageObj(mainImage))
          .width(1920)
          .height(600)
          .fit("crop")
          .auto("format")
          .url()}
        altText={mainImage.alt}
      />
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            <PageTitle title={title} />
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>
          {/* <aside className={styles.metaContent}>
            {publishedAt && (
              <div className={styles.publishedAt}>
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? formatDistance(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), "MMMM Mo, yyyy")}
              </div>
            )}
            {authors && <AuthorList items={authors} title="Authors" />}
            {categories && (
              <div className={styles.categories}>
                <h3 className={styles.categoriesHeadline}>Categories</h3>
                <ul>
                  {categories.map((category) => (
                    <li key={category._id}>{category.title}</li>
                  ))}
                </ul>
              </div>
            )}
          </aside> */}
        </div>
      </Container>
    </article>
  );
}

export default BlogPost;
